import React from 'react'

//about page function displays simple facts about the developer
export default function AboutPage() {
    return (
        <div className='container text-center'>

            Page Under Development by Prabal Chhatkuli.
            please see instruction page for more details.

            Github:<a href='https://github.com/prabalchhatkuli/Gameofthetiger'> Github for Game of Tiger </a>

        </div>
    )
}
